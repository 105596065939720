import { agGridCss, themeBalhamCss } from './print-report.styles'

function print(gridOptions, getCurrentTargetLayer, fileName = 'download') {
  const api = gridOptions.api;
  api.setDomLayout('print');

  setTimeout(() => {
    const head = '<div id="ag-custom-header" class="row" style="margin:0px; padding: 0;">' +
      '<div style="margin: auto; width: fit-content;">' +
      '<div style="text-align: center;">' +
      '<h3><p></p></h3>' +
      '<h4><p>' + getCurrentTargetLayer.description + '</p></h4>' +
      '<p></p>' +
      '</div>' +
      '</div>' +
      '</div>';
    const css = '<style>' +
      '.ag-numeric-cell {' +
      'text-align: right;' +
      '}' +
      '.ag-numeric-header {' +
      'text-align: right;' +
      '}' +
      '.ag-icon-group {' +
      'display: none;' +
      '}' +
      agGridCss +
      '.ag-header-container {' +
      'height: 64px;' +
      'background-color: #f5f7f7 !important;' +
      'border: 1px solid #BDC3C7;' +
      '}' +
      '.ag-column-drop-empty-message {' +
      'display: none !important;' +
      '}' +
      '.ag-cell-wrapper {' +
      'color: #000 !important;' +
      '}' +
      themeBalhamCss +
      '</style>';
    const html = '<html><head>' +
      css +
      `<title>${fileName}</title>` +
      '</head>' +
      '<body>' +
      head +
      '<div class="ag-theme-balham">' +
      '<div class="ag-root-wrapper ag-ltr ag-layout-normal" style="margin: auto; width: fit-content; margin-top: 50px;">' +
      document.querySelectorAll("div.ag-column-drop")[0].outerHTML +
      document.querySelectorAll("div.ag-header")[0].outerHTML +
      document.querySelectorAll("div.ag-body-viewport.ag-layout-print")[0].outerHTML +
      '</div>' +
      '</div>' +
      '<body></html>';
    const win = window.open('', "_blank", "width=" + screen.width + ",height=" + screen.height + ",toolbars=yes,scrollbars=yes,status=yes,resizable=yes");

    setTimeout(() => {
      win.document.write(html);
      win.document.close();
      win.focus();
      setTimeout(() => {
        win.print();
        api.setDomLayout(null);
        setTimeout(() => {
          win.close();
        });
      }, 250);
    });
  });
}


export default print