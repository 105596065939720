<template src="./document.html"></template>

<style lang="scss">
@import './document.scss';
</style>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import manageColumns from '@/backoffice/modules/reporting/mixins/manage-columns.js';
import { DocumentStatus } from '@/backoffice/modules/inventory/domain/document.js';
import documentServices from '@/backoffice/modules/inventory/services/document.services.js';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment';
import formatters from '@/core/components/common/grid/formatters/formatters.js';
import inventoryScan from '@/backoffice/modules/inventory/components/side-panel/inventory-product-scan/inventory-product-scan.vue';
import inventoryProductCount from '@/backoffice/modules/inventory/components/side-panel/inventory-product-count/inventory-product-count.vue';
import inventoryProductAdjustment from '@/backoffice/modules/inventory/components/side-panel/inventory-product-adjustment/inventory-product-adjustment.vue';
import searchBar from '@/core/components/common/search-bar/search-bar.vue';
import grid from '@/core/components/common/grid/persisted-grid.vue';
import contextMenu from '@/core/components/common/context-menu/context-menu.vue';
import { GridId, AgFilter, AgAllowedAggFuncs, MultiFilterParams } from '@/core/components/common/grid/grid.const.js'

export default {
  mixins: [manageColumns],
  name: 'Document',
  props: {
    id: [Number, String]
  },
  components: {
    searchBar,
    grid,
    contextMenu,
  },

  data(){
    return { 
      currentNode: null,
      inventoryProductTemplate: '',
      gridApi: null,
      columnApi: null,
      gridOptions: {
        getRowId: (params) => params.data?.id
      },
      qtyColumnTitle: null,
      documentLoaded: false,
      filters: [],
      contextMenuButtons: [{
        button: 'prinDocument',
        icon: ['fas', 'fa-print'],
        text: 'Inventory.Print',
        callback: this.printGrid
      },
      {
        button: 'exportDocument',
        icon: ['fas', 'fa-file-csv'],
        text: 'Inventory.ExportCSV',
        callback: this.onBtnExport
      },
      {
        button: 'seperator',
        isSeperator: true,
        callback: ()=>{return;}
      },
      {
        button: 'deleteDocument',
        icon: ['fal', 'fa-times'],
        text: 'Button.Delete',
        isDisabled: !this.canDelete,
        callback: this.onDeleteDocument
      }],
      sidePanelComponents: {
        'InventoryScan': [{ component: inventoryScan }],
        'InventoryProductAdjustment': [{ component: inventoryProductAdjustment }],
        'InventoryProductCount': [{ component: inventoryProductCount }]
      }
    }
  },
  computed: {
    ...mapGetters('App', ['isMobile', 'getConfigs']),
    ...mapGetters('Account', ['getUser', 'getCurrentTargetLayerId']),
    ...mapGetters('Inventory', ['getDocument']),
    getGridId() {
      return GridId.Inventory
    },
    documentClosed(){
      return this.getDocument.documentStatus === DocumentStatus.Closed;
    },
    documentOpen(){
      return this.getDocument.documentStatus === DocumentStatus.Open;
    },
    documentSuspended(){
      return this.getDocument.documentStatus === DocumentStatus.Suspended;
    },
    canForceOpen(){
      return this.documentOpen && this.getUser && this.getDocument.userID !== this.getUser._UserID;
    },
    gridData() {
      if (!this.getDocument?.documentBodies)
        return []

      return this.getDocument.documentBodies.filter(body => !body.isDeleted)
    },
    columnDefs() {
      return [  
        { hide: true, field: 'documentBodyType', headerName: this.$t('DocumentBody.Columns.DocumentBodyType'), headerTooltip: this.$t('DocumentBody.Columns.DocumentBodyType'), filter: AgFilter.SetColumnFilter, valueFormatter: (params) => params?.data?.documentBodyType && this.$t('DocumentBody.Columns.' + params?.value), filterValueGetter: (params) => this.$t('DocumentBody.Columns.' + params?.data?.documentBodyType) },                       
        { field: 'itemDescription', 
          headerName: this.$t('DocumentBody.Columns.ItemDescription'), 
          minWidth: 100,
          headerTooltip: this.$t('DocumentBody.Columns.ItemDescription'), 
          cellClass:'ag-cell-overflow',
          filter: 'agTextColumnFilter',
          cellRenderer: 'menuRenderer',
          cellRendererParams: {
            canUpdate: row => row.data?.canUpdate && (this.getDocument.documentStatus !== DocumentStatus.Suspended),
            canDelete: row => row.data?.canDelete && (this.getDocument.documentStatus !== DocumentStatus.Suspended),
            clickMenuLink: row => this.editEntity(row.data),
            editEntity: row => this.editEntity(row.data),
            delete: this.delete
          },
          enableRowGroup: false,
          filterParams: MultiFilterParams.NormalizeTextFilters,
          allowedAggFuncs: AgAllowedAggFuncs.Text
        },
        { hide: this.isMobile, field: 'itemUPC', headerName: this.$t('DocumentBody.Columns.ItemUPC'), headerTooltip: this.$t('DocumentBody.Columns.ItemUPC'), cellClass: 'stringType', filterParams: MultiFilterParams.NormalizeTextFilters },
        { hide: this.isMobile, field: 'itemSKU', headerName: this.$t('DocumentBody.Columns.ItemSKU'), headerTooltip: this.$t('DocumentBody.Columns.ItemSKU'), cellClass: 'stringType', filterParams: MultiFilterParams.NormalizeTextFilters },        
        { hide: true, field: 'subDepartmentCode', headerName: this.$t('DocumentBody.Columns.SubDepartmentCode'), headerTooltip: this.$t('DocumentBody.Columns.SubDepartmentCode'), filterParams: MultiFilterParams.NormalizeTextFilters, allowedAggFuncs: AgAllowedAggFuncs.Text },                 
        { hide: true, field: 'subDepartmentDescription', headerName: this.$t('DocumentBody.Columns.SubDepartmentDescription'), headerTooltip: this.$t('DocumentBody.Columns.SubDepartmentDescription'), filterParams: MultiFilterParams.NormalizeTextFilters, allowedAggFuncs: AgAllowedAggFuncs.Text },
        { hide: true, field: 'departmentCode', headerName: this.$t('DocumentBody.Columns.DepartmentCode'), headerTooltip: this.$t('DocumentBody.Columns.DepartmentCode'), filterParams: MultiFilterParams.NormalizeTextFilters },
        { hide: true, field: 'departmentDescription', headerName: this.$t('DocumentBody.Columns.DepartmentDescription'), headerTooltip: this.$t('DocumentBody.Columns.DepartmentDescription'), filterParams: MultiFilterParams.NormalizeTextFilters, allowedAggFuncs: AgAllowedAggFuncs.Text },
        { hide: true, field: 'vendorCode', headerName: this.$t('DocumentBody.Columns.VendorCode'), headerTooltip: this.$t('DocumentBody.Columns.VendorCode'), filterParams: MultiFilterParams.NormalizeTextFilters, allowedAggFuncs: AgAllowedAggFuncs.Text },
        { hide: true, field: 'vendorName', headerName: this.$t('DocumentBody.Columns.VendorName'), headerTooltip: this.$t('DocumentBody.Columns.VendorName'), filterParams: MultiFilterParams.NormalizeTextFilters, allowedAggFuncs: AgAllowedAggFuncs.Text },
        { hide: true, field: 'uomCode', headerName: this.$t('DocumentBody.Columns.UomCode'), headerTooltip: this.$t('DocumentBody.Columns.UomCode'), filter: AgFilter.SetColumnFilter, allowedAggFuncs: AgAllowedAggFuncs.Text },
        { hide: this.isMobile, field: 'uomDescription', headerName: this.$t('DocumentBody.Columns.UomDescription'), headerTooltip: this.$t('DocumentBody.Columns.UomDescription'), filter: AgFilter.SetColumnFilter, allowedAggFuncs: AgAllowedAggFuncs.Text },
        { hide: this.isMobile, field: 'format', headerName: this.$t('DocumentBody.Columns.Format'), headerTooltip: this.$t('DocumentBody.Columns.Format'), filter: AgFilter.SetColumnFilter, allowedAggFuncs: AgAllowedAggFuncs.Text },
        { hide: this.isMobile, field: 'avgUnitCost', headerName: this.$t('DocumentBody.Columns.AvgUnitCost'), headerTooltip: this.$t('DocumentBody.Columns.AvgUnitCost'), valueFormatter: formatters.currencyFormatter, filter: AgFilter.NumberColumnFilter },
        { hide: this.isMobile, field: 'currentInventoryQty', headerName: this.$t('DocumentBody.Columns.CurrentInventoryQty'), headerTooltip: this.$t('DocumentBody.Columns.CurrentInventoryQty'), filter: AgFilter.NumberColumnFilter },
        { hide: true, field: 'currentInventoryCost', headerName: this.$t('DocumentBody.Columns.CurrentInventoryCost'), headerTooltip: this.$t('DocumentBody.Columns.CurrentInventoryCost'), filter: AgFilter.NumberColumnFilter, valueFormatter: formatters.currencyFormatter },
        { hide: this.isMobile, field: 'qty', headerName: this.qtyColumnTitle, headerTooltip: this.qtyColumnTitle, filter: AgFilter.NumberColumnFilter },
        { hide: true, field: 'avgCost', headerName: this.$t('DocumentBody.Columns.AvgCost'), headerTooltip: this.$t('DocumentBody.Columns.AvgCost'), filter: AgFilter.NumberColumnFilter, valueFormatter: formatters.currencyFormatter },
        { field: 'varianceQty', headerName: this.$t('DocumentBody.Columns.VarianceQty'), headerTooltip: this.$t('DocumentBody.Columns.VarianceQty'), filter: AgFilter.NumberColumnFilter, cellRenderer: 'negativeValueRenderer' },
        { field: 'varianceCost', headerName: this.$t('DocumentBody.Columns.VarianceCost'), headerTooltip: this.$t('DocumentBody.Columns.VarianceCost'), filter: AgFilter.NumberColumnFilter, cellRenderer: 'negativeAmountRenderer' },
        { hide: true, field: 'isDeleted', headerName: this.$t('DocumentBody.Columns.IsDeleted'), headerTooltip: this.$t('DocumentBody.Columns.IsDeleted'), filter: AgFilter.SetColumnFilter, filterParams: { valueFormatter: (params) => this.$t(`Reporting.DataDef.${params.value}`) }, valueFormatter: (params) => this.$t(`Reporting.DataDef.${params.value}`) }
      ]
    }
  },
  watch: {
    getCurrentTargetLayerId() {
      this.changePage('documents');
    },
    'getDocument.userID': {
      handler(newVal, oldVal){
        if(newVal && oldVal && newVal !== oldVal){
          this.initializeCurrentDocument();
        }
      }
    }
  },

  mounted(){
    this.initializeCurrentDocument()
  },

  beforeDestroy(){
    this.resetDocument()
  },

  methods: {
    ...mapActions('Inventory', [
      'fetchDocument',
      'updateDocument',
      'forceOpenUpdateDocument',
      'deleteDocument'
    ]),
    ...mapActions('Product', ['updateItemsForSale']),
    ...mapMutations('Inventory', { setDocument: 'SET_DOCUMENT', resetDocument: 'RESET_DOCUMENT' }),
    ...mapMutations('Breadcrumb', {
      setBreadcrumbContext: 'SET_CONTEXT'
    }),

    onGridReady(params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi
    },

    async initializeCurrentDocument(){
      try {
        await this.fetchDocument(Number.parseInt(this.id));
      } catch (error) {
        prompts.error({ text: this.$t('Document.fetchDocumentError') });
      }
      
      this.setBreadcrumbContext({ document: this.getDocument })
      this.inventoryProductTemplate = documentServices.getSidePanelTemplate(this.getDocument.documentType);
      const deleteBtnIndex = this.contextMenuButtons.findIndex(btn => btn.button === "deleteDocument");
      this.contextMenuButtons[deleteBtnIndex].isDisabled = !(this.getDocument.canDelete && this.getDocument.documentStatus != DocumentStatus.Closed);
      this.qtyColumnTitle = this.$t('DocumentBody.Columns.' + this.getDocument.documentType)
      this.documentLoaded = !!this.getDocument?.id
    },

    addItem(){
      this.$sidePanel.show(this.sidePanelComponents['InventoryScan'], null, { backgroundIsDisabled: true });
    },
    async suspendDocument(){
      const document = this.getDocument;
      document.documentStatus = DocumentStatus.Suspended;
      try{
        await this.updateDocument(document)
        await this.initializeCurrentDocument()
      }   
      catch(response){
        this.validateUpdateDocumentResponse(response)
      }  
    },
    async closeDocument(){
      const document = this.getDocument;
      document.documentStatus = DocumentStatus.Closed;
      try {
        await this.updateDocument(document);
      }
      catch (response) {
        this.validateUpdateDocumentResponse(response)
      }

      await this.validateAndUpdateItemsForSale(document);
      this.changePage('documents');
    },
    async continueDocument(){
      const document = cloneDeep(this.getDocument);
      document.documentStatus = DocumentStatus.Open;
      try{
        await this.updateDocument(document)
        await this.initializeCurrentDocument()
      }
      catch(response){
        this.validateUpdateDocumentResponse(response)
      }
    },
    async forceopenDocument(){
      
      const result = await prompts.warning({
        html: this.$t(
          'Inventory.Messages.ForceOpenDocument', 
          { 
            originalUser: this.getDocument.userName,
            date: moment(this.getDocument.documentLastDatetime).format('yyyy-MM-DD') ,
            time: moment(this.getDocument.documentLastDatetime).format('HH:mm')
          })
      });

      if (result.isConfirmed) {
        this.forceOpen()
      }
    },
    async forceOpen() {
      const document = cloneDeep(this.getDocument);
      document.userID = this.getUser._UserID;
      document.userEmail = this.getUser.email;
      document.userName = this.getUser.firstName + ' ' + this.getUser.firstName;
      document.userUsername = this.getUser.username;
      await this.forceOpenUpdateDocument(document);
    },
    async onDeleteDocument() {

      const result = await prompts.warning({
        html: this.$t('Inventory.Messages.DeleteDocument')
      })

      if (result.isConfirmed) {
        this.processDeleteDocument()
      }
    },
    async processDeleteDocument() {
      try {
        await this.deleteDocument(this.getDocument);
      }
      catch (response) {
        this.validateUpdateDocumentResponse(response)
      }

      this.changePage('documents');
    },
    printGrid() {
      const colDefs = cloneDeep(this.columnDefs)
      const columnState = cloneDeep(this.columnApi.getColumnState())
      const columnGroupState = cloneDeep(this.columnApi.getColumnGroupState())
      const isPivotMode = cloneDeep(this.columnApi.isPivotMode())

      for (let i = 0; i < colDefs.length; i++){
        if (colDefs[i].cellRenderer){
          colDefs[i].cellRenderer = undefined
        }
      }

      this.gridApi.closeToolPanel()
      this.gridApi.setColumnDefs(colDefs)
      this.gridApi.sizeColumnsToFit()

      if (isPivotMode)
        this.columnApi.setPivotMode(isPivotMode)

      if (columnGroupState)
        this.columnApi.setColumnGroupState(columnGroupState)

      if (columnState) {
        this.columnApi.applyColumnState({
          state: columnState,
          applyOrder: true
        })
      }

      this.gridApi.expandAll()
      this.gridApi.setDomLayout("print")
      window.print()
      this.gridApi.setDomLayout(null)

      this.gridApi.setColumnDefs(this.columnDefs)

      if (isPivotMode)
        this.columnApi.setPivotMode(isPivotMode)

      if (columnGroupState)
        this.columnApi.setColumnGroupState(columnGroupState)

      if (columnState) {
        this.columnApi.applyColumnState({
          state: columnState,
          applyOrder: true
        })
      }
      this.gridApi.sizeColumnsToFit()
    },

    onBtnExport() {
      this.gridApi.exportDataAsCsv({
        suppressQuotes: null,
        columnSeparator: null,
        customHeader: null,
        customFooter: null
      });
    },

    // Dirt, should be moved to backend
    async validateAndUpdateItemsForSale(document) {
      const itemsUpcs = documentServices.getNewItemsUpsForSale(document.documentBodies)

        if (itemsUpcs.length > 0) {
          try {
            await this.updateItemsForSale(itemsUpcs)
          } catch (error) {
            prompts.error({
              text: this.$t('Inventory.Messages.ErrorOnInventoryManagement')
            })
          }
        }
    },

    editEntity(documentBody){
      this.$sidePanel.show(this.sidePanelComponents[this.inventoryProductTemplate], { documentBody });
    },
    async delete(node){
      this.currentNode = node;

      const result = await prompts.warning({
        html: this.$t('Inventory.Messages.DeleteDocumentBody')
      });
      
      if (!result.isConfirmed) {
        return
      }

      this.currentNode.data.remove = true;
      this.currentNode.data.ignore = false;
      const document = cloneDeep(this.getDocument);

      try {
        await this.updateDocument(document);
      } 
      catch (response) {
        this.validateUpdateDocumentResponse(response)
      }

      this.currentNode.data.isDeleted = true;
    },
    
    validateUpdateDocumentResponse(response){
      if (response.message == "ForceOpen") {
        prompts.warning({
          html: this.$t(
            'Inventory.Messages.UpdateBlockedForceOpen', 
            { 
              user: this.getDocument.userName,
              date: moment(this.getDocument.documentLastDatetime).format('yyyy-MM-DD'),
              time: moment(this.getDocument.documentLastDatetime).format('HH:mm')
            })
        });
      }
      else {
        prompts.error({
          text: this.$t('Inventory.Messages.GetDocumentError')
        });
      }
    },

    changePage(page){
      this.$router.push({ name: page });
    },
    filtersChanged(filters){
      this.filters = filters;
    }
  }
}
</script>