<template inline-template v-cloak src="./read-report.html"></template>

<script>
import moment from 'moment';
import axios from 'axios';
import manageColumns from '@/backoffice/modules/reporting/mixins/manage-columns.js';
import extractTime from '@/backoffice/modules/reporting/mixins/extract-time.js';
import printReport from '@/backoffice/modules/reporting/mixins/print-report/print-report.js';
import targetDropdown from '@/core/components/common/drop-down/target-dropdown/target-dropdown.vue';
import { reportClient } from '@/backoffice/modules/reporting/services/report.client.js';
import { reportUserCustomizationServices } from '@/backoffice/modules/reporting/services/report-user-customization.services.js';
import { mapGetters, mapActions, mapMutations } from 'vuex';
import isEqual from 'lodash/isEqual';
import searchBar from '@/core/components/common/search-bar/search-bar.vue'
import grid from  '@/core/components/common/grid/grid.vue'
import periodPicker  from '@/backoffice/modules/reporting/components/period-picker/period-picker.vue'

export default {
  mixins: [manageColumns, extractTime],
  name: 'create',
  props: {
    reportType: String,
    _ReportDataModelID: [String, Number]
  },
  components: {
    searchBar,
    grid,
    periodPicker,
    targetDropdown
  },
  data() {
    return {
      gridReadyPromiseResolver: null,
      targetChangedEventPromiseResolver: null,
      componentInitialized: false,
      imageButton: "@/img/back.svg",
      cancelToken: null,
      source: null,
      updateRucTimer: null,
      preventRucSave: false,
      filters: [],
      model: {},
      gridOptions: {
        columnDefs: [],
        sideBar: true,
        multiSortKey: 'ctrl',
        rowGroupPanelShow: 'always',
        suppressDragLeaveHidesColumns: true,
        suppressMakeColumnVisibleAfterUnGroup: true,
        rowSelection: 'single',
        tooltipShowDelay: 750,
        defaultColDef: {
          sortable: true,
          resizable: true
        },
        onGridReady: (params) => {
          params.api.closeToolPanel();
          this.gridReadyPromiseResolver();
        },
        onColumnPivotChanged: this.cuReportCustomization,
        onColumnPivotModeChanged: this.cuReportCustomization,
        onColumnRowGroupChanged: this.cuReportCustomization,
        onColumnVisible: this.cuReportCustomization,
        onDragStopped: this.cuReportCustomization, // Used instead of any of drag event like onColumnMoved and onColumnResized
        onSortChanged: this.cuReportCustomization,
        onColumnValueChanged: this.cuReportCustomizationAndPinRowBottom,
        onFilterChanged: this.cuReportCustomizationAndPinRowBottom,
        onRowDataChanged: this.rowDataChanged
      },
      reportDataModel: {},
      rucs: [],
      currentRuc: {},
      target: {},
      period: null,
      user: {},
    }
  },

  computed:{
    ...mapGetters('Account', ['getCurrentTargetLayer', 'getCurrentTargetLayerId']),
    ...mapGetters('App', ['isMobile']),    
    ...mapGetters('DataModels', ['hasView', 'hasMandatoryDate', 'getMandatoryDateProperty', 'getScopeProperty'])
  },

  watch: {
    getCurrentTargetLayerId(){
      this.$router.push({ name: this.$route.meta.associatedParentRouteName });
    }
  },
  beforeDestroy() {
    if(this.source){
      this.source.cancel('OPERATION_CANCELED');
    }
    if(this.gridOptions.api){
      this.gridOptions.api.setRowData([]);
      this.gridOptions.api.destroy();
      this.grid = null;
    }
  },
  created() {
    this.user = this.$store.getters['Account/getUser'];

    Promise.all([
        new Promise(resolve => {
          this.gridReadyPromiseResolver = resolve;
        }),
        new Promise(resolve => {
          this.targetChangedEventPromiseResolver = resolve;
        })
      ]).then(async () => {
          await this.getReportDataModel();
          await this.getReportTypeModel();
          await this.getReportsCustomization();

          await this.getData();

          this.componentInitialized = true;
      });
  },
  methods: {
    ...mapActions('DataModels', ['getModel']),
    ...mapActions('Reporting', [
      'getReportData',
      'deleteReportUserCustomization',
      'updateReportUserCustomization',
      'createReportUserCustomization'
    ]),
    ...mapMutations('Breadcrumb', {
      setBreadcrumbContext: 'SET_CONTEXT'
    }),

    //Set ag-grid api objects
    async getReportDataModel() {
      const _ReportDataModelID = Number.parseInt(this._ReportDataModelID);

      const response = await reportClient.getReport(_ReportDataModelID, true);

      if (response.status == 200) {
        this.reportDataModel = response.data;
        this.setBreadcrumbContext({ reportDataModel: this.reportDataModel })
      }
    },
    async getReportTypeModel(){
      const model = await this.getModel(this.reportType);
      
      if(model){
        this.model = model;
        if(this.gridOptions.columnDefs.length == 0){
          this.gridOptions.api.setColumnDefs(this.setAgGridColumns(this.model.columnDefs, this.reportDataModel.genericListColumn, this.reportDataModel.genericListGroup, this.model, true, false, null));
        } 
      }
    },
    async getReportsCustomization() {
      const response = await reportUserCustomizationServices.getReportsCustomization(this.reportDataModel._ReportDataModelID, this.user._UserID, false);

      if(response.status == 200) {
        this.rucs = response.data;
        this.selectCurrentRuc();
      }
    },
    async getData() {
      if(this.source){
          this.source.cancel('OPERATION_CANCELED');
      }
      this.cancelToken = axios.CancelToken;
      this.source = this.cancelToken.source();
      this.gridOptions.api.showLoadingOverlay();
      const dataFilters = this.setDataFilters();

      const request = {
        params: {
          getChildren: false,
          modelName: this.reportType,
          reportDataModelID: this.reportDataModel._ReportDataModelID,
          filters: dataFilters 
        },
        cancelToken: { cancelToken: this.source.token }
      };

      const dataSet = await this.getReportData(request);

      if (dataSet) {
        this.gridOptions.api.setRowData(this.setData(dataSet));
        this.gridOptions.api.hideOverlay();
        this.applyRuc();
      }
    },
    setData(data){
      for (let i = 0; i < data.length; i++) {
        data[i] = this.deconstruct(data[i], data[i]);
      }
      return data;
    },
    async deleteRuc(){
      const _ReportUserCustomizationID = this.currentRuc._ReportUserCustomizationID;
      await this.deleteReportUserCustomization([_ReportUserCustomizationID]);

      this.rucs = this.rucs.filter(function(r) { return r._ReportUserCustomizationID != _ReportUserCustomizationID});
      this.selectCurrentRuc();
    },
    async cuReportCustomization() {
      if (!this.preventRucSave && this.componentInitialized) {
        this.preventRucSave = true;

        this.currentRuc.period = this.period;
        this.currentRuc.customization = JSON.stringify(this.gridOptions.columnApi.getColumnState());
        this.currentRuc.listFilter = JSON.stringify(this.gridOptions.api.getFilterModel());
        this.currentRuc.pivot = this.gridOptions.columnApi.isPivotMode();

        //Update
        if (this.currentRuc._ReportUserCustomizationID > 0 && this.currentRuc._UserID > 0) {
          //We update only if it changed
          if (!isEqual(this.currentRuc, this.rucCopy)) {
            await this.updateReportUserCustomization([this.currentRuc]);
          }

          this.rucCopy = Object.assign({}, this.currentRuc)
          this.preventRucSave = false;
        }
        //Create
        else {
          //Set _UserID with vue $set method to avoid reactivity problems
          this.$set(this.currentRuc, '_UserID', this.user._UserID);
          this.currentRuc._ReportDataModelID = this.reportDataModel._ReportDataModelID;
          this.currentRuc._ReportUserCustomizationID = null;

          const ruc = await this.createReportUserCustomization([this.currentRuc]);

          if (ruc) {
            this.currentRuc._ReportUserCustomizationID = ruc._ReportUserCustomizationID; 
            this.rucs.push(this.currentRuc);
            this.rucCopy = Object.assign({}, this.currentRuc)
          }

          this.preventRucSave = false;
        }
      }
    },
    async periodChanged(period) {
      this.period = period;
      this.cuReportCustomization();
      await this.getData();
    },

    onTargetChanged(target) {
      this.target._TargetID = target.targetId;
      if (!this.componentInitialized) {
        this.targetChangedEventPromiseResolver()
      }
      else {
        this.getData();
      }
    },

    applyRuc(){
      this.preventRucSave = true;
      this.period = this.currentRuc.period;
      this.gridOptions.columnApi.setPivotMode(this.currentRuc.pivot);
      try { 
        this.gridOptions.columnApi.applyColumnState({ 
          state: JSON.parse(this.currentRuc.customization),
          applyOrder: true
        }); 
      } catch(e){ 
        this.gridOptions.columnApi.resetColumnState();
      }
      try{ this.gridOptions.api.setFilterModel(JSON.parse(this.currentRuc.listFilter)); } catch(e){ this.gridOptions.api.setFilterModel([]); }

      setTimeout(() => {
        this.preventRucSave = false;
      }, 1000);
    },
    selectCurrentRuc(){
      this.currentRuc = {};
      for (let i = 0; i < this.rucs.length; i++) {
        //If there is a ruc with a user, use it
        if(this.rucs[i]._UserID > 0){
          this.currentRuc = Object.assign({}, this.rucs[i]);
          break;
        }
      }
      if(!this.currentRuc._ReportUserCustomizationID){
        this.currentRuc = Object.assign({}, this.rucs[0]);
      }
      this.rucCopy = Object.assign({}, this.currentRuc);
      this.applyRuc();
    },
    setDataFilters(){
      let dataFilters = [];
      const dates = this.convert(this.period);
      
      const mandatoryDateProperty = this.getMandatoryDateProperty(this.model.name);

      //All reporting models should have a mandatoryDate property
      if(mandatoryDateProperty){
        
        //If model has a view, we get the mandatoryDate property and build filter using it.  
        if(this.hasView(this.model.name)){
          dataFilters.push({ Column: mandatoryDateProperty.name, Operator: '>=', Value: [moment(dates.start).format('YYYY-MM-DD')] });
          dataFilters.push({ Column: mandatoryDateProperty.name, Operator: '<=', Value: [moment(dates.end).format('YYYY-MM-DD')] });

          const scopeProperty = this.getScopeProperty(this.model.name);
          if(scopeProperty){
            dataFilters.push({ Model: 'TargetLink', MainColumn: scopeProperty.name, JoinedColumn: '_TargetLayerID', Column: '_TargetID', Value: [this.target._TargetID] });
          }
        }
        //Else, it uses a stored procedure and need the static filter StartDate, EndDate and _TargetID
        else{
          dataFilters = [
              { Column: 'StartDate', Value: [moment(dates.start).format('YYYY-MM-DD')] },
              { Column: 'EndDate', Value: [moment(dates.end).format('YYYY-MM-DD')] },
              { Column: '_TargetID', Value: [this.target._TargetID] }
            ];
        }
      }
      
      return dataFilters;
    },
    onPinnedRowBottom() {
      setTimeout(() => {
        const pinnedC = {};
        const columns = this.gridOptions.columnApi.getValueColumns();
        columns.forEach((c) => {
          pinnedC[c.colDef.field] = this.pinnedColumn(c.colDef.field);
        });
        if (JSON.stringify(pinnedC) !== JSON.stringify({})) {
          this.gridOptions.api.setPinnedBottomRowData([pinnedC]);
        }
        else{
          this.gridOptions.api.setPinnedBottomRowData([]);
        }
      }, 500);
    },
    pinnedColumn(field) {
      let sum = 0;
      const hasOwnPropertyCI = this.hasOwnPropertyCI;
      this.gridOptions.api.forEachNodeAfterFilter(function (node) {
        if (node.level == 0) {
          if (node.aggData && hasOwnPropertyCI(node.aggData, field)){
            sum += !isNaN(node.aggData[field]) ? parseFloat(node.aggData[field]) : 0;
          }
          else if(node.data){
            sum += !isNaN(node.data[field]) ? parseFloat(node.data[field]) : 0;
          }
        }
      });
      if(sum % 1 != 0){
        sum = sum.toFixed(2);
      }
      return sum;
    },
    rowDataChanged() {
      this.onPinnedRowBottom();
      this.gridOptions.api.refreshCells(); //Refresh cell to make sure valueGetter are refreshed
    },
    cuReportCustomizationAndPinRowBottom() {
      this.cuReportCustomization();
      this.onPinnedRowBottom();
    },
    filtersChanged(filters){
      this.filters = filters;
    },
    onPrint() {
      printReport(this.gridOptions, this.getCurrentTargetLayer, this.getPrintFileName)
    }
  }
}
</script>