<template src="./promotion-report.html"></template>
<style lang="scss" src="./promotion-report.scss">

</style>

<script>
import { mapActions, mapGetters } from 'vuex';
import prompts from '@/core/tools/notifications/notifications';
import searchBar from '@/core/components/common/search-bar/search-bar.vue';
import targetDropdown from '@/core/components/common/drop-down/target-dropdown/target-dropdown.vue';
import periodPicker from '@/backoffice/modules/reporting/components/period-picker/period-picker.vue';
import grid from '@/core/components/common/grid/grid.vue';
import extractTime from '@/backoffice/modules/reporting/mixins/extract-time.js';
import formatters from '@/core/components/common/grid/formatters/formatters.js';
import printReport from '@/backoffice/modules/reporting/mixins/print-report/print-report.js';

export default {
    name: 'Promotion-Report',
    mixins: [extractTime],
    components: {
        searchBar,
        targetDropdown,
        periodPicker,
        grid,
    },

    data() {
        return {
            gridReadyPromiseResolver: null,
            targetChangedEventPromiseResolver: null,
            componentInitialized: false,
            filters: null,
            period: '-0w',
            startDate: null,
            endDate: null,
            targetLayerId: null,
            targetDropdownKey: 0,
            movements: [],
            gridOptions: {
                sideBar: true,
                rowGroupPanelShow: 'always',
                groupIncludeTotalFooter: true,
                defaultColDef: {
                    sortable: true,
                    resizable: true,
                    filter: 'agSetColumnFilter',
                    cellRendererParams: {
                        // turn off the row count as it's difficult to understand what the count means
                        suppressCount: true
                    }
                },
                columnDefs: [
                    {
                        headerName: this.$t('Promotion.Report.Site'), headerTooltip: this.$t('Promotion.Report.Site'),
                        children: [
                            { field: 'enterprise', hide: true, headerName: this.$t('Promotion.Report.Enterprise'), headerTooltip: this.$t('Promotion.Report.Enterprise'), enableRowGroup: true, rowGroupIndex: 0 },
                            { field: 'store', hide: true, headerName: this.$t('Promotion.Report.Store'), headerTooltip: this.$t('Promotion.Report.Store'), enableRowGroup: true, rowGroupIndex: 1 }
                        ]
                    },
                    {
                        headerName: this.$t('Promotion.Promotion'), headerTooltip: this.$t('Promotion.Promotion'),
                        children: [
                            { field: 'associatedTarget', headerName: this.$t('Promotion.Report.AssociatedTarget'), headerTooltip: this.$t('Promotion.Report.AssociatedTarget'), enableRowGroup: true },
                            { field: 'scope', headerName: this.$t('Promotion.Report.Scope'), headerTooltip: this.$t('Promotion.Report.Scope'), enableRowGroup: true, rowGroupIndex: 2 },
                            { field: 'discountType', headerName: this.$t('Promotion.Report.DiscountType'), headerTooltip: this.$t('Promotion.Report.DiscountType'), enableRowGroup: true, rowGroupIndex: 3 },
                            { field: 'promotionDescription', headerName: this.$t('Promotion.Report.Description'), headerTooltip: this.$t('Promotion.Report.Description'), enableRowGroup: true },
                            { field: 'upc', headerName: this.$t('Promotion.Report.Upc'), headerTooltip: this.$t('Promotion.Report.Upc'), enableRowGroup: true },
                            { field: 'promotionCode', headerName: this.$t('Promotion.Report.PromotionCode'), headerTooltip: this.$t('Promotion.Report.PromotionCode'), enableRowGroup: true },
                            { field: 'isInstant', headerName: this.$t('Promotion.Report.Instant'), headerTooltip: this.$t('Promotion.Report.Instant'), enableRowGroup: true, cellRenderer: 'booleanRenderer', valueGetter: (params) => params?.data?.isInstant ? params.data.isInstant.toString() : 'false' },
                            { field: 'discountValue', headerName: this.$t('Promotion.Report.DiscountValue'), headerTooltip: this.$t('Promotion.Report.DiscountValue'),valueFormatter: this.formatDiscountValue, enableRowGroup: true },
                            { field: 'promotionStartDate', headerName: this.$t('Promotion.Report.StartDate'), headerTooltip: this.$t('Promotion.Report.StartDate'), valueFormatter: formatters.dateFormatter,enableRowGroup: true },
                            { field: 'promotionEndDate', headerName: this.$t('Promotion.Report.EndDate'), headerTooltip: this.$t('Promotion.Report.EndDate'), valueFormatter: formatters.dateFormatter,enableRowGroup: true }
                        ],
                    },
                    {
                        headerName: this.$t('Promotion.Report.Metrics'), headerTooltip: this.$t('Promotion.Report.Metrics'),
                        children: [
                            { field: 'quantity', headerName: this.$t('Promotion.Report.Quantity'), headerTooltip: this.$t('Promotion.Report.Quantity'), enableValue: true, filter: 'agNumberColumnFilter', aggFunc: 'sum' },
                            { field: 'totalApplied', headerName: this.$t('Promotion.Report.TotalApplied'), headerTooltip: this.$t('Promotion.Report.TotalApplied'), enableValue: true, filter: 'agNumberColumnFilter', valueFormatter: formatters.currencyFormatter, aggFunc: 'sum' },
                            { field: 'totalSales', headerName: this.$t('Promotion.Report.TotalSales'), headerTooltip: this.$t('Promotion.Report.TotalSales'), enableValue: true, filter: 'agNumberColumnFilter', valueFormatter: formatters.currencyFormatter, aggFunc: 'sum' }
                        ]
                    }
                ],
                onGridReady: () => {
                    this.gridReadyPromiseResolver();
                }
            },
            periodPickerOptions : [
                { name : "RangePicker.today", value : "-0d", selected : false },
                { name : "RangePicker.yesterday", value : "-1d", selected : false },
                { name : "RangePicker.this", selected : false, choices : [
                    { name : "RangePicker.thisWeek", value : "-0w", selected : false },
                    { name : "RangePicker.thisMonth", value : "-0m", selected : false },
                    { name : "RangePicker.thisQuarter", value : "-0q", selected : false },
                    { name : "RangePicker.thisYear", value : "-0y", selected : false }
                    ]
                },
                { name : "RangePicker.last", selected : false, choices : [
                    { name : "RangePicker.lastWeek", value : "-1w", selected : false },
                    { name : "RangePicker.lastMonth", value : "-1m", selected : false },
                    { name : "RangePicker.lastQuarter", value : "-1q", selected : false },
                    { name : "RangePicker.lastYear", value : "-1y", selected : false }
                    ]
                },
                { name : "RangePicker.custom", dates: "", selected : false, isDateRangePicker : true, value: "" }
            ]
        }
    },

    computed: {
        ...mapGetters('Account', ['getCurrentTargetLayer', 'getCurrentTargetLayerId']),
        rowData(){
            return this.movements
        },
        getFormattedDate() {
            const date = new Date()

            return `${date.getFullYear()}${(date.getMonth()+1).toString().padStart(2, '0')}${(date.getDate()).toString().padStart(2, '0')}`
        },
        getPrintFileName() {
            
            return `${this.$t('Promotion.Report.ReportTitle')} ${this.getFormattedDate}`
        }
    },

    watch: {
        getCurrentTargetLayerId() {
            this.targetDropdownKey++;
        }
    },

    created() {
        Promise.all([
            new Promise(resolve => {
                this.gridReadyPromiseResolver = resolve;
            }),
            new Promise(resolve => {
                this.targetChangedEventPromiseResolver = resolve;
            })
        ]).then(async () => {
            await this.fetchReportPromotions();
            this.componentInitialized = true;
        });
    },

    methods: {
        ...mapActions('Reporting', ['getPromotionsReport']),
        async fetchReportPromotions() {
            const dates = this.convert(this.period)
            this.startDate = dates.start
            this.endDate = dates.end

            const request = {
                startDate: this.startDate,
                endDate: this.endDate,
                targetLayerId: this.targetLayerId
            }

            this.gridOptions.api.showLoadingOverlay();

            try {
                this.movements = await this.getPromotionsReport(request)
            }
            catch (e) {
              prompts.error({
                  text: this.$t('Promotion.Messages.GetPromotionReportError')
              });
            }

            this.gridOptions.api.hideOverlay();
        },
        periodChanged(period) {
            this.period = period;
            this.fetchReportPromotions();
        },
        onTargetChanged(target) {
            this.targetLayerId = target.targetLayerId;
            if (!this.componentInitialized) {
                this.targetChangedEventPromiseResolver()
            }
            else {
                this.fetchReportPromotions();
            }
        },
        filtersChanged(filters) {
            this.filters = filters;
        },
        formatDiscountValue(param) {
            if (!param.data)
                return;

            if(param.data.discountType?.includes("%"))
                return formatters.percentageFormatter({ value: param.data.discountValue * 100 });            

            return formatters.currencyFormatter({ value: param.data.discountValue });
        },
        onPrint() {
            printReport(this.gridOptions, this.getCurrentTargetLayer, this.getPrintFileName)
        }
    }
}
</script>


