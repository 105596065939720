<template src="./promotion-product-add.html"></template>
<style lang="scss">
  @import "./promotion-product-add.scss";
</style>
<script>
import promotionProductScan from '@/backoffice/modules/promotion/components/sidePanel/promotion-product-scan/promotion-product-scan.vue';
import ProductInfoHeader from '@/core/components/layout/side-panel/product-info-header/product-info-header.vue';
import prompts from '@/core/tools/notifications/notifications';
import {toCurrency} from '@/core/functions/format-value.js';
import {mapActions,mapGetters, mapMutations} from 'vuex'
import { calculateDiscountedPrice, PromotionItem, PromotionItemCategory } from '@/backoffice/modules/promotion/domain/promotion.js';
import { PromotionValueFormatter, PromotionTypeGetter} from '@/backoffice/modules/promotion/promotion-getters/promotion-getters.js';
import { Item } from '@/backoffice/modules/item/domain/item.js'
import { permissionsDictionary } from '@/core/security/permissions-dictionary.js';
import { promotionClient } from '@/backoffice/modules/promotion/services/promotion.client.js'
import { UnitUOM } from '@/backoffice/modules/inventory/domain/document';
import { cloneDeep } from 'lodash';

export default {
  name:'promotion-product-add',
  components:{
    ProductInfoHeader
  },
  props:{
    templatePayload:{
      type: Object,
      default: function () {
        return { productUpc: null, category: PromotionItemCategory.GetY };
      }
    }
  },
  data(){
    return {
      product: new Item(),
      averageLastFourWeeks: 0,
      productInventory: 0,
      averageUnitCost: 0
    }
  },
  
  computed:{
    ...mapGetters('Promotion', ['getPromotion', 'getPromotionDescription']),
    ...mapGetters('Account', ['hasPermission']),
    productUpc(){      
      return this.templatePayload.productUpc
    },
    detailMeanCost(){
      return toCurrency(this.averageUnitCost);
    },
    detailPrice(){
      return toCurrency(this.product.itemPrice);
    },
    expectedPrice(){
      const productPrice = this.product.itemPrice || 0
      const discountedPrice = calculateDiscountedPrice(this.getPromotion.discount, productPrice)
      return toCurrency(discountedPrice);
    },
    itemExist(){
      const discount = this.getPromotion.discount;
      const searchedPromotionItem = new PromotionItem()
      searchedPromotionItem.upc = this.productUpc
      searchedPromotionItem.category = this.itemCategory

      if(discount?.items?.length > 0){
        return discount.hasItem(searchedPromotionItem)
      }
      return false;
    },
    promotionDiscountType(){
      return PromotionTypeGetter(this.getPromotion);
    },
    promotionDiscountValue(){
      return PromotionValueFormatter(this.getPromotion);
    },
    promotionMaxUsage(){
      return this.getPromotion.maxUsage
    },
    readProductPermission(){
      return this.hasPermission([permissionsDictionary.PRODUCT_READ])
    },
    readInventoryPermission(){
      return this.hasPermission([permissionsDictionary.INVENTORY_READ])
    },
    itemCategory() {
      return this.templatePayload.category || PromotionItemCategory.GetY
    },
    isScaleItem() {
      return UnitUOM.uomCode.toLocaleLowerCase() !== this.product.unitOfMeasurement?.toLocaleLowerCase()
    },
    isBxGy() {
      return this.getPromotion?.discount?.isBxGy
    }
  },
  mounted(){
    this.init();
    this.$nextTick(() => this.$refs.btnAddItem.focus());
  },
  methods:{
    ...mapActions('Product', ['fetchProductsAverageLastWeeks', 'fetchProduct']),
    ...mapActions('Inventory', ['fetchProductInventory']),
    ...mapMutations("Promotion", {addPromotionItems: 'ADD_PROMOTION_ITEMS', setPromotion: 'SET_PROMOTION'}),
    cancel(){
      this.$sidePanel.close()
    },
    async init(){
      this.product = await this.fetchProduct(this.productUpc);
      
      const productsAverageLastWeeksResult = await this.fetchProductsAverageLastWeeks({itemUpcs: [this.productUpc], nbOfWeeks: 4});
      if (productsAverageLastWeeksResult) {
        this.averageLastFourWeeks = productsAverageLastWeeksResult[this.productUpc]?.amount;
      }

      if(this.readInventoryPermission){
        const productInventoryResult = await this.fetchProductInventory(this.productUpc);
        this.productInventory = productInventoryResult?.qty || 0;
        this.averageUnitCost = productInventoryResult?.averageUnitCost || 0;
      }
    },
    async addPromotionItem(){
      try {
        if (this.itemExist)
          return prompts.info({text: this.$t('Promotion.Messages.ProductAddedYet')})

        if (this.isScaleItem)
          return prompts.error({text: this.$t('Promotion.Messages.CantAddWeightItem')})

        const editedPromotion = cloneDeep(this.getPromotion)
        editedPromotion.addItem(this.product, this.itemCategory)
        const savedItems = await promotionClient.addPromotionItems(editedPromotion.discount.items, editedPromotion.discount)
        if(!savedItems){
          prompts.error({text: this.$t('Promotion.Messages.SavePromotionError')})
          return
        }
        if(savedItems?.length <= 0){
          prompts.error({text: this.$t('Promotion.Messages.ValidationError')})
          return
        }
        this.addPromotionItems(savedItems)
        this.$sidePanel.show([{ component: promotionProductScan }], {category: this.itemCategory}, { backgroundIsDisabled: true })
      } 
      catch (error) {
        prompts.error({text:this.$t('Promotion.Messages.AddItemSaveError')})
      }
    }
  }
}
</script>
