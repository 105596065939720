<template src="./grid.html"></template>
<style lang="scss" src="./grid.scss"></style>

<script>
import { AgGridVue  } from "@ag-grid-community/vue";
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { ClipboardModule } from '@ag-grid-enterprise/clipboard';
import { ColumnsToolPanelModule } from '@ag-grid-enterprise/column-tool-panel';
import { MultiFilterModule } from '@ag-grid-enterprise/multi-filter';
import { RowGroupingModule } from '@ag-grid-enterprise/row-grouping';
import { RangeSelectionModule } from '@ag-grid-enterprise/range-selection';
import { GridChartsModule } from '@ag-grid-enterprise/charts';
import { SideBarModule } from '@ag-grid-enterprise/side-bar';
import { ExcelExportModule } from '@ag-grid-enterprise/excel-export';
import { FiltersToolPanelModule } from '@ag-grid-enterprise/filter-tool-panel';
import { MenuModule } from '@ag-grid-enterprise/menu';
import { SetFilterModule } from '@ag-grid-enterprise/set-filter';
import { stringFunction } from '@/core/functions';

import checkboxRenderer from  './renderers/checkbox-renderer/checkbox-renderer.vue';
import checkboxTreeRenderer from  './renderers/checkbox-renderer/checkbox-tree-renderer.vue';
import detailRenderer from  './renderers/detail-renderer/detail-renderer.vue';
import dropdownRenderer from './renderers/dropdown-renderer/dropdown-renderer.vue';
import groupRenderer from './renderers/group-renderer/group-renderer.vue';
import menuRenderer from  './renderers/menu-renderer/menu-renderer.vue';
import negativeAmountRenderer from  './renderers/negative-amount-renderer/negative-amount-renderer.vue';
import negativeValueRenderer from  './renderers/negative-value-renderer/negative-value-renderer.vue';
import optionsRenderer from  './renderers/options-renderer/options-renderer.vue';
import periodRenderer from './renderers/period-renderer/period-renderer.vue';
import panelRenderer from './renderers/panel-renderer/panel-renderer.vue';
import integerInputRenderer from  './renderers/integer-input-renderer/integer-input-renderer.vue';
import selectInputRenderer from  './renderers/select-input-renderer/select-input-renderer.vue';
import currencyInputRenderer from  './renderers/currency-input-renderer/currency-input-renderer.vue';
import toggleRenderer from  './renderers/toggle-renderer/toggle-renderer.vue';
import booleanRenderer from './renderers/boolean-renderer/boolean-renderer.vue';
import clickCallbackRenderer from './renderers/click-callback-renderer/click-callback-renderer.vue';
import linkRenderer from './renderers/link-renderer/link-renderer.vue';
import iconRenderer from './renderers/icon-renderer/icon-renderer.vue';
import availabilityMaximumRenderer from './renderers/availability-maximum-renderer/availability-maximum-renderer.vue'
import optionsToolPanel from './toolPanels/options-tool-panel/options-tool-panel.vue';
import { getTranslationForRangePicker } from '@/core/functions/';
import { Datatype } from '@/core/datatype/';
import { mapGetters, mapMutations } from 'vuex';
import { GridId, AgToolPanels, ToolPanels, AgFilter, Icons } from '@/core/components/common/grid/grid.const.js'
import cloneDeep from 'lodash/cloneDeep'
import debounce from 'lodash/debounce'
import isEqual from 'lodash/isEqual';
import { MaxValueForSizeColumnsToFit } from '@/core/components/common/grid/grid.const'

const ReloadState = {
  UNSET: 0,
  TARGET_CHANGED: 1,
  DATA_RESET: 2,
  RELOAD_STATE: 3
}

export default {
  name: 'persisted-grid',
  model: {
    prop: 'componentGrid'
  },
  props:{
    options: Object,
    gridTitle: String,
    filters: Array,
    rowData: Array,
    columnDefs: Array,
    pagination: Boolean,
    defaultFilters: {
      type: Object,
      default: () => null
    },
    overlayLoadingTemplateMessage: {
      type: String,
      default: function () {
        return this.$t('Reporting.LoadingData');
      }
    },
    gridId: {
      type: String,
      default: null,
      required: true,
      validator(value) {
        if (!value)
          return false

        const gridIdIsValid = Object.values(GridId).some(id => id === value)
        return gridIdIsValid
      }
    },
    pivotMode: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AgGridVue,
    booleanRenderer,
    checkboxTreeRenderer,
    checkboxRenderer,
    detailRenderer,
    dropdownRenderer,
    groupRenderer,
    iconRenderer,
    menuRenderer,
    negativeAmountRenderer,
    negativeValueRenderer,
    optionsRenderer,
    periodRenderer ,
    panelRenderer,
    integerInputRenderer,
    currencyInputRenderer,
    toggleRenderer,
    clickCallbackRenderer,
    linkRenderer,
    availabilityMaximumRenderer,
    optionsToolPanel,
    selectInputRenderer
  },
  data(){
    return {
      modules: [ClientSideRowModelModule, CsvExportModule, ClipboardModule, ColumnsToolPanelModule, RowGroupingModule, 
                SideBarModule, ExcelExportModule, FiltersToolPanelModule, MenuModule, SetFilterModule, MultiFilterModule, RangeSelectionModule, GridChartsModule],
      overlayNoRowsTemplate: '<span>' + this.$t('General.Messages.NoData') + '</span>',
      overlayLoadingTemplate: '<span class="ag-overlay-loading-center">' + this.overlayLoadingTemplateMessage + '</span>',
      excelStyles: [{ id: `${Datatype._String}Type`, dataType: Datatype._String, }],
      avoidGridStatePersistence: true,
      gridApi: null,
      columnApi: null,
      reloadGridState: ReloadState.UNSET,
      defaultGridOptions: {
        headerHeight: 50,
        rowHeight: 50,
        columnDefs: [],
        icons: Icons,
        sideBar: {
          toolPanels: [
            {
              id: ToolPanels.Columns.id,
              labelKey: ToolPanels.Columns.labelKey,
              iconKey: ToolPanels.Columns.iconKey,
              toolPanel: ToolPanels.Columns.toolPanel,
              toolPanelParams: {
                suppressPivots: !this.pivotMode,
                suppressPivotMode: !this.pivotMode,
              }
            },            
            AgToolPanels.Filters,
            {
              id: ToolPanels.Options.id,
              labelKey: ToolPanels.Options.labelKey,
              iconKey: ToolPanels.Options.iconKey,
              toolPanel: ToolPanels.Options.toolPanel,
              toolPanelParams: {
                gridId: this.gridId,
                userId: 0,
                targetLayerId: 0,
                resetGridState: this.resetGridStateToDefault
              }
            }
          ],
          hiddenByDefault: true,
          defaultToolPanel: 'columns'
        },
        rowSelection: 'single',
        rowGroupPanelShow: 'onlyWhenGrouping',
        suppressDragLeaveHidesColumns: true,
        overlayNoRowsTemplate: '<span>' + this.$t('Purchase.Messages.NoOrderData') + '</span>',
        onColumnPivotChanged: this.saveUserGridStateOnEvent,
        onColumnPivotModeChanged: this.saveUserGridStateOnEvent,
        onColumnVisible: this.saveUserGridStateOnEvent,
        onDragStopped: this.saveUserGridStateOnEvent,
        onSortChanged: this.saveUserGridStateOnEvent,
        onColumnValueChanged: this.saveUserGridStateOnEvent,
        onFilterChanged: this.saveUserFilterModel,
        onColumnPinned: this.saveUserGridStateOnEvent,
        onColumnResized: debounce(this.saveUserGridStateOnEvent, 200),
        onColumnMoved: this.saveUserGridStateOnEvent,
        onFirstDataRendered: () => {
          if (!this.isMobile) {
            this.setFilterModelFromUserGridState()
            this.setGridStateFromUserGridState()
          }
          else {
            this.setDefaultFilterModel()
          }

          this.$nextTick(() => {
            if (!this.isMobile)
              this.avoidGridStatePersistence = false

            this.gridApi.hideOverlay()
          })

          let pageSize = this.userGridState?.pageSize
          
          if (!pageSize) 
              pageSize = 100

          this.gridApi.paginationSetPageSize(pageSize)
        }
      }
    }
  },

  computed:{    
    ...mapGetters('App', ['isMobile', 'getResize']),
    ...mapGetters('Grid', ['getUserGridState']),
    ...mapGetters('Account', ['getCurrentTargetLayerId', 'getUser']),
    headerHeight() { return this.gridOptions.headerHeight ?? 50; },
    gridOptions() {
      const mergedOptions = {
        ...this.defaultGridOptions,
        defaultColDef: {
          resizable: true,
          sortable: true,
          enableRowGroup: true,
          enableValue: !this.isMobile,
          enablePivot: !this.isMobile,
          floatingFilter: !this.isMobile,
          filter: AgFilter.MultiColumnFilter,
          cellDataType: false
        },
        sideBar: !this.isMobile ? this.defaultGridOptions.sideBar : null,
        enableRangeSelection: !this.isMobile,
        ...this.options
      }
      return mergedOptions
    },
    canPersistGridState() {
      return !this.avoidGridStatePersistence && this.gridApi && this.columnApi && this.rowData?.length
    },
    userId() {
      return this.getUser._UserID
    },
    userGridState(){
      const userGridState = this.getUserGridState[this.userId]
      if (!userGridState) return null
      const gridState = userGridState[this.gridId]
      if(!gridState) return null
      const targetLayerState = gridState[this.getCurrentTargetLayerId]
      return targetLayerState || null
    }
  },

  watch:{
    getResize(){
      this.resizeColumns();
    },
    filters() {
      this.filterData();
    },
    getCurrentTargetLayerId() {
      this.gridApi.showLoadingOverlay()
      this.reloadGridState = ReloadState.RELOAD_STATE
    },
    rowData: {
      handler(newData, oldData) {
        if (!isEqual(newData, oldData) && this.reloadGridState === ReloadState.RELOAD_STATE) {
          this.$nextTick(() => {
            this.reloadGridState = ReloadState.UNSET
            this.setFilterModelFromUserGridState()
            this.setGridStateFromUserGridState()
          })
        }
      },
      deep: true
    }
  },

  beforeDestroy() {
    this.avoidGridStatePersistence = true
    this.gridApi.destroy()
    this.gridApi = null
    this.columnApi = null
  },
  
  methods: {
    ...mapMutations('Grid', { setUserGridState: 'SET_USER_GRID_STATE', removeUserGridState: 'REMOVE_USER_GRID_STATE' }),
    onGridReady: function(params) {
      this.gridApi = params.api
      this.columnApi = params.columnApi

      this.gridApi.showLoadingOverlay()
      this.gridApi.sizeColumnsToFit()

      if (!this.isMobile) {
        this.gridApi.setSideBarVisible(true)
        this.gridApi.closeToolPanel()
      }

      this.$emit('onGridReady', params)
    },
    onSelectionChanged: function(params) {
      this.$emit('onSelectionChanged', params)
    },
    resizeColumns(){
      this.$nextTick(() => {
        const cols = this.columnApi.getAllDisplayedColumns();
        if(this.isMobile || cols.length < MaxValueForSizeColumnsToFit){
            this.gridApi.sizeColumnsToFit();
        }
        else{
          this.columnApi.autoSizeAllColumns();
        }
      });
    },
    getLocaleText: function(params) {
      const gridKey = 'grid.' + params.key;
      const value = this.$t(gridKey);
      return this.compareCI(value, gridKey) ? params.defaultValue : value;
    },
    getContextMenuItems: function(){
      const result = [   
        "copy", "copyWithHeaders", "copyWithGroupHeaders", "paste", "separator",
        {
          name: this.$t("grid.export"),
          subMenu: [
            {
              name: this.$t("grid.csvExport"),
              action: this.exportAsCsv
            },
            {
              name: this.$t("grid.excelExport"),
              action: this.exportAsExcel
            }
          ]
        },
        "chartRange"
      ];
      return result;
    },
    exportAsCsv: function(){    
      this.gridApi.exportDataAsCsv({
        processCellCallback: (params) => {
          const colDef = params.column.getColDef()
          // try to reuse valueFormatter from the colDef
          if (colDef.valueFormatter) {
            const valueFormatterParams = {
              ...params,
              data: params.node.data,
              node: params.node,
              colDef: params.column.getColDef()
            };
            return colDef.valueFormatter(valueFormatterParams);
          }
          return params.value;
        },
        fileName: this.gridTitle,
        sheetName: this.gridTitle
      });
    },
    exportAsExcel: function(){
      this.gridApi.exportDataAsExcel({
        processCellCallback: (params) => {
          const colDef = params.column.getColDef()
          // try to reuse valueFormatter from the colDef
          if (colDef.valueFormatter) {
            const valueFormatterParams = {
              ...params,
              data: params.node.data,
              node: params.node,
              colDef: params.column.getColDef()
            };
            return colDef.valueFormatter(valueFormatterParams);
          }
          return params.value;
        },
        fileName: this.gridTitle,
        sheetName: this.gridTitle
      });
    },
    isExternalFilterPresent(){
      return !!this.filters && this.filters.length > 0;
    },
    doesExternalFilterPass(node){
      const displayedColumns = [...this.columnApi.getAllDisplayedColumns(), ...this.columnApi.getRowGroupColumns()];

      const hasMatch = displayedColumns.some(column => {
        const colField = column.colDef.field;
        let rawValue = null;

        if(node.data && colField)
          rawValue = node.data[colField];
        else if(node.key)
          rawValue = node.key
        else
          return

        const rawValueIsStringable = !!rawValue && typeof rawValue !== 'object'
        const fieldValue = rawValueIsStringable ? rawValue.toString() : undefined;

        return this.filters.some(filter => {
          if(colField == 'period') {
            let translatedRangePicker = getTranslationForRangePicker(node.data.period);
            translatedRangePicker = stringFunction.normalize(translatedRangePicker);
            const filterValue = stringFunction.normalize(filter);
            return translatedRangePicker.includes(filterValue);
          }

          let formattedValue = this.getGridFormattedValue(node, column, fieldValue);
          if (!formattedValue) {
            return false;
          }
          
          formattedValue = stringFunction.normalize(formattedValue.toString());

          const filterValue = stringFunction.normalize(filter);
          return formattedValue.includes(filterValue);
        });
      });

      return hasMatch;
    },
    getGridFormattedValue(node, column, value) {
      const nodeFilter = Object.assign({ rowPinned: node.isRowPinned() }, node);
      const filterParams = {
        value: value,
        data: nodeFilter.data,
        node: nodeFilter,
        colDef: column.colDef
      };

      if (column.colDef.valueGetter) {
        value = column.colDef.valueGetter(filterParams);

        // Reset value with the return value from valueGetterFilter because ValueFormatter is apply with the modification fromn ValueGetter.
        filterParams.value = value;
      }
      
      if (column.colDef.valueFormatter)
        value = column.colDef.valueFormatter(filterParams);

      return value;
    },
    filterData() {
      if (this.gridApi) {
        this.gridApi.onFilterChanged();
      }
    },
    setGridStateFromUserGridState() {
      this.gridApi.sizeColumnsToFit()

      const columnState = this.userGridState?.columnState
      const columnGroupState = this.userGridState?.columnGroupState
      const isPivotMode = this.userGridState?.isPivotMode

      if (isPivotMode) {
        this.columnApi.setPivotMode(isPivotMode)
      }

      if (columnGroupState) {
        this.columnApi.setColumnGroupState(columnGroupState)
      }

      if (columnState) {
        this.columnApi.applyColumnState({
          state: columnState,
          applyOrder: true
        })
      }
    },
    setFilterModelFromUserGridState() {
      const filterModel = this.userGridState?.filterModel
      if (filterModel) {
        this.gridApi.setFilterModel(filterModel)
      }
      else {
        this.setDefaultFilterModel()
      }
    },
    updateUserGridState(state) {

      if(!state)
        return
      
      this.setUserGridState({
        gridId: this.gridId,
        userId: this.userId,
        targetLayerId: this.getCurrentTargetLayerId,
        gridState: state
      })
    },
    saveUserFilterModel(params) {
      if (!this.canPersistGridState)
        return

      const newState = {
        ...this.userGridState,
        filterModel: cloneDeep(params.api.getFilterModel())
      }
      this.updateUserGridState(newState)
    },
    saveUserGridStateOnEvent(params) {
      if (!this.canPersistGridState)
        return

      const newState = {
        ...this.userGridState,
        columnState: cloneDeep(params.columnApi.getColumnState()),
        columnGroupState: cloneDeep(params.columnApi.getColumnGroupState()),
        isPivotMode: cloneDeep(params.columnApi.isPivotMode())
      }
      this.updateUserGridState(newState)
    },
    resetGridStateToDefault() {
      this.removeUserGridState({ gridId: this.gridId, userId: this.userId, targetLayerId: this.getCurrentTargetLayerId })

      this.setDefaultFilterModel()
      this.columnApi.setPivotMode(false)
      this.columnApi.resetColumnState()
      this.columnApi.getColumnGroupState()
      this.gridApi.sizeColumnsToFit()
      const resetColumnWidth = this.columnDefs.filter(c => c.width).map(c => ({ colId: c.field || c.colId, width: c.width }))
      if (resetColumnWidth.length > 0)
        this.columnApi.applyColumnState({
          state: resetColumnWidth
        })
    },
    setDefaultFilterModel() {
      const defaultFilters = this.defaultFilters ? this.defaultFilters : null
      this.gridApi.setFilterModel(defaultFilters)
    },
    setLoading() {
      this.gridApi?.showLoadingOverlay()
    }
  }
}
</script>